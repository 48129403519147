import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { StyledErrorWrapper } from 'components/layoutUi'
import { useReactiveVar } from '@apollo/client'
import { blockLeadsDisplay } from 'apollo'
import AddressBlock, { overrideAddressBlockStyle, useAddressStore } from 'components/addressBlock'
import DoctorBlock from 'components/doctorBlock/DoctorBlock'
import { useDoctorStore } from 'components/doctorBlock/hooks/state'
import LoadingSpinner from 'components/LoadingSpinner'


export default function DoctorAddressCollection({onSubmit, displaySubmitError, isSleepNewLeads, ...pageNavigatorProps}) {
  const { isDoctorComplete, setIsDoctorComplete } = useDoctorStore()
  const [ isDoctorLoading, setIsDoctorLoading ] = useState( true )
  const isAddressComplete = useAddressStore( state => state.isAddressComplete )
  const leadsDisplayBlocked = useReactiveVar( blockLeadsDisplay )

  useEffect( () => {
    if ( leadsDisplayBlocked && window.location.pathname !== `/new-patient-intake/sleep/thank-you` ) pageNavigatorProps.nextPage()
  }, [] )

  useEffect( () => {
    if ( !leadsDisplayBlocked ) {
      // avoid resetting these values on the progress bar if need to display mask fitting page
      pageNavigatorProps.onHandleChange({
        doctorAcceptedFlag: isDoctorComplete ? `1` : ``
      })
    }
  }, [ isDoctorComplete ] )

  useEffect( () => {
    // avoid resetting these values on the progress bar if need to display mask fitting page
    if ( !leadsDisplayBlocked ) {
      pageNavigatorProps.onHandleChange({
        addressAcceptedFlag: isAddressComplete ? `1` : ``
      })
    }
  }, [ isAddressComplete ] )

  overrideAddressBlockStyle({
    headerStyle: `font-semibold font-inter text-[#0057A8] text-center sm:text-3xl lg:text-[42px] sm:pb-4 lg:pb-7`,
    containerStyle: ``,
    wrapperStyle: `max-w-none text-center`,
    view: {
      editActionClassName: `underline cursor-pointer text-deepSleepBlue`
    }
  })

  return (
    <div className="sm:mx-auto lg:mx-0 max-w-[672px] relative">
      {isDoctorLoading && (
        // no initial loading state needed for Address Block, use the same loading state as Doctor Block
        <div className="absolute inset-0 bg-white bg-opacity-1 z-10 flex items-start justify-center">
          <LoadingSpinner />
        </div>
      )}
      <DoctorBlock
        onSuccess={() => { setIsDoctorComplete( true ) }}
        onExistingDoctor={() => { setIsDoctorComplete( true ) }}
        headerStyle="font-semibold font-inter text-[#0057A8] sm:text-3xl lg:text-[42px] sm:pb-4 lg:pb-7"
        containerStyle="max-w-2xl"
        doctorSearchStyle="!max-w-none"
        onLoadingComplete={() => { setIsDoctorLoading( false ) }}
      />

      <AddressBlock
        addressType=""
        shouldSkipMountFetch
        shouldSkipValidation
        subHeaderText={`Lastly, enter your street address`}
        snlHeaderText={`Your Shipping Address`}
      />

      {displaySubmitError && (
        <StyledErrorWrapper>
          {`We're sorry but our servers have encountered an issue processing this submission. Please call a patient care specialist at ${AF_SUPPORT_PHONE_NUMBER} for help.`}
        </StyledErrorWrapper>
      )}
      <div className="mx-auto w-80 mt-6 space-y-6">
        <button
          className={`btn ${isSleepNewLeads ? `btn-primary` : `btn-secondary `}`}
          onClick={onSubmit}
          disabled={!isDoctorComplete || !isAddressComplete}
        >
          {`${isSleepNewLeads ? `Submit Order` : `Complete Form`}`}
        </button>
      </div>
    </div>
  )
}

DoctorAddressCollection.propTypes = {
  onSubmit: PropTypes.func,
  displaySubmitError: PropTypes.bool,
  onHandleChange: PropTypes.func,
  isSleepNewLeads: PropTypes.bool
}