import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CheckIcon from './CheckIcon'
import QuickViewModal from 'modules/sleepNewLeads/Components/QuickViewModal'

const ModelSelector = ({
  heading,
  name,
  machines,
  selectedValue,
  onChange,
  showOnMobile
}) => {
  const [ showModal, setShowModal ] = useState( false )
  const [ machineData, setMachineData ] = useState({
    machineImages: [],
    cpapMachineDmeID: ``,
    cpapMachineModel: ``,
    lastOrderCpapMachine: ``
  })

  const handleMachineClick = ( machine ) => {
    setMachineData({
      machineImages: {
        ...machineData,
        machine: machine.name,
        items: [ ...machine.imgSrc ]
      },
      cpapMachineDmeID: machine.dme_id,
      cpapMachineModel: machine.name
    })
    setShowModal( true )
  }

  return (
    <>
      {showOnMobile && <div className="mt-4">
        {showModal && (
          <QuickViewModal
            onCloseModal={() => setShowModal( false )}
            isMachine
            machineData={machineData}
            onMachineChange={( lastOrderCpapMachine ) => {
              const machine = {
                cpapMachineDmeID: machineData.cpapMachineDmeID,
                cpapMachineModel: machineData.cpapMachineModel,
                lastOrderCpapMachine: !lastOrderCpapMachine ? `2` : lastOrderCpapMachine
              }
              setShowModal( true )
              return onChange({
                target: {
                  name,
                  value: machine
                }
              })
            }}
          />
        )}
        <div className="font-inter sm:text-xl md:text-[28px] sm:text-center lg:text-left font-medium sm:mb-5 md:mb-11">{heading}</div>
        {machines.length === 0 && (
          <p className="text-center">{`No models for current brand`}</p>
        )}
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 sm:gap-[18px] lg:gap-[24px] sm:mx-auto lg:mx-0 sm:px-8 lg:px-0 items-center">
          {machines.map( ( machine ) => {
            return (
              <div key={machine.dme_id} className="mb-6" >
                <div
                  className={`cursor-pointer p-1 flex flex-col relative rounded-md ${machine.dme_id === selectedValue ? `border border-deepSleepBlue` : ``}`}
                  onClick={() => handleMachineClick( machine )}
                >
                  {machine.dme_id === selectedValue && <CheckIcon className="rounded-tl-sm left-0" />}
                  <div className="aspect-square flex items-center justify-center overflow-hidden cursor-pointer">
                    <img
                      src={machine.imgSrc[0]}
                      className="w-full h-full object-contain"
                      alt={machine.name}
                    />
                  </div>
                </div>
                <button
                  className="btn-quick-view"
                  onClick={() => handleMachineClick( machine )}
                >
                  {`Select`}
                </button>
                <p
                  className="cursor-pointer sm:text-sm md:text-xl font-medium font-inter text-center"
                >
                  {machine.name}
                </p>
              </div>
            )
          })}
        </div>
      </div>}
    </>
  )
}

ModelSelector.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string,
  machines: PropTypes.array,
  selectedValue: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
  onChange: PropTypes.func,
  showOnMobile: PropTypes.bool
}

export default ModelSelector
