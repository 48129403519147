import AccountHeader from './components/AccountHeader'
import { useAccountStore } from './state'
import { fetchAccountData } from './fetchAccountData'
import AccountTracker from '../../components/accountTracker/AccountTracker'
import { AccountInfo, Insurance, Invoice, Orders, Physician, ProductResupply } from './tiles'
import LoadingSpinner from '../../components/LoadingSpinner'

function AccountV2() : JSX.Element {
  // fetch all data for tiles
  fetchAccountData()
  const { evaluateIsLoading } = useAccountStore()
  const isLoading = evaluateIsLoading()

  return (
    isLoading ?
      <div className="my-16">
        <LoadingSpinner
          messageStyling="font-light text-lg text-center my-5"
          message="Please hold tight while we get your account info..."
        />
      </div>
      :
      <div className="max-w-4xl mx-auto my-10">
        <AccountHeader />
        <div className="columns-1 md:columns-2 justify-center">
          <AccountTracker />
          <Orders />
          <ProductResupply />
          <Physician />
          <Insurance />
          <AccountInfo />
          <Invoice />
        </div>
      </div>
  )
}

export default AccountV2