import * as React from 'react'
import QualifyForm from 'modules/qualifyForm'
import { useReactiveVar } from '@apollo/client'
import { maintenancePreviousURL } from 'apollo'
import { useNavigate } from 'react-router-dom'
import { checkMaintenanceFlag } from 'modules/maintenanceListener/utils'
import LoadingSpinner from 'components/LoadingSpinner'
import { reportToSentry } from 'utils/reportToSentry'
import Logo from '../headerBarV2/Logo'

function MaintenancePage() {
  const [ statusMessage, setStatusMessage ] = React.useState( `` )
  const [ loading, setLoading ] = React.useState( false )
  const navigate = useNavigate()
  const previousURL = useReactiveVar( maintenancePreviousURL )

  const handleNavigate = () => {
    setLoading( true )
    setStatusMessage( `` )
    checkMaintenanceFlag()
      .then( data => {
        setLoading( false )
        if ( data?.meta?.status === `OK` && typeof data?.data?.status === `boolean` ) {
          if ( !data.data.status ) {
            // site was taken out of maintenance mode
            navigate( previousURL )
            return maintenancePreviousURL( `` )
          }
          return setStatusMessage( `We are currently still working on the site... Thank you for your patience.` )
        }
        setStatusMessage( `We encountered an error checking the site status. Please reload the page and try again...` )
      })
      .catch( ( err ) => {
        setLoading( false )
        setStatusMessage( `We encountered an error checking the site status. Please reload the page and try again...` )
        reportToSentry( new Error( `checkMaintenanceFlag on Maintenance Page Error`, {
          cause: err
        }) )
      })
  }

  return (
    <div className="text-center w-full mx-auto">
      <div className="mx-auto sm:w-11/12 md:w-max mt-8">
        <Logo withoutLink={false} />
      </div>
      <div className="w-full bg-deepSleepBlue text-white mt-6">
        <p className="text-xl text-center max-w-4xl mx-auto px-5 py-4 font-light">
          <span className="font-semibold">{`Important Notice: `}</span>
          {` Due to Hurricane Helene, we are currently experiencing service disruptions. We apologize for any delays in communication and shipping. Thank you for your understanding and patience during this time.`}
        </p>
      </div>
      <div className="mt-8 max-w-3xl w-11/12 mx-auto pb-10">
        <h1 className="mb-6 sm:text-3xl md:text-5xl">{`Our server is taking a quick nap!`}</h1>
        <p className="my-2">
          {`We are currently undergoing maintenance.`}
        </p>
        {
          previousURL &&
          <div className="max-w-xl mx-auto flex flex-col justify-center">
            <div className="my-2 mx-auto">
              <button className="btn-secondary" onClick={handleNavigate} disabled={loading}>
                {loading ? <LoadingSpinner rings={3} height="1.5rem" width="1.5rem" /> : `Check Status`}
              </button>
            </div>
            {
              statusMessage &&
              <p className="text-error">{statusMessage}</p>
            }
          </div>
        }
        <p className="mt-7 mb-2">
          {`If you already have an account with us or were recently referred to us by your doctor and are trying to complete your order, we apologize for any inconvenience this may cause, but please check back later.`}
        </p>
        <p className="mt-7 mb-2">
          {`If you are new to Aeroflow Sleep, fill out the form below to estimate your out-of-pocket costs with insurance for fresh CPAP supplies.`}
        </p>
      </div>
      <QualifyForm isSiteOffline />
    </div>
  )
}

export default MaintenancePage