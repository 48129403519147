import React, { useEffect, useState, useRef } from 'react'
import { ElfsightWidget } from 'react-elfsight-widget'
import { reportToSentry } from 'utils/reportToSentry'

type HomepageReviewWidgetProps = {
  reviewHeading?: string
}

const HomepageReviewWidget = ({ reviewHeading } : HomepageReviewWidgetProps ) : JSX.Element => {
  const widgetId = process.env.REACT_APP_ELFSIGHT_WIDGET_ID || ``
  const [ isLoading, setIsLoading ] = useState( true )
  const hasReportedError = useRef( false )

  useEffect( () => {
    if ( !ElfsightWidget || !widgetId ) {
      if ( !hasReportedError.current ) {
        reportToSentry(
          new Error( `Homepage review section: error loading Elfsight widget. Widget ID: ${widgetId}` )
        )
        hasReportedError.current = true
      }
    } else {
      setIsLoading( false )
    }
  }, [ ElfsightWidget ] )

  if ( isLoading ) return <></>
  return (
    <div className="max-w-[1280px] mx-auto overflow-hidden sm:pt-9 pb-7 md:pt-[42px] md:pb-[50px] sm:px-4 md:px-6 sm:min-h-[350px] lg:min-h-[450px]">
      <h2 className="max-w-5xl mx-auto text-center font-normal sm:text-2xl sm:pb-4 md:pb-16 md:text-[44px] text-deepSleepBlue md:!leading-[52px]">{reviewHeading || `Aeroflow Is The Trusted Choice For CPAP Supplies`}</h2>
      <ElfsightWidget widgetId={widgetId} modern />
    </div>
  )
}

export default HomepageReviewWidget
