import { ApolloError, useQuery, useReactiveVar } from '@apollo/client'
import { customerAddressesQuery, getProductsQueryBySkuArray, regionsQuery } from 'graphql/queries'
import { reportToSentry } from '../../utils/reportToSentry'
import { useAccountStore } from './state'
import { accountTrackerDataFetch } from '../../components/accountTracker/utils/utils'
import { useEffect } from 'react'
import { accountNavOptions, patientIdFromAppServer } from '../../apollo'
import { apolloClient } from 'apollo/apolloClient'
import { fetchResupplyOptions } from '../resupply2/hooks/fetchPromises'
import { getResupplyProductsViaOptionsFetchErrorDisplayMessage } from './utils'
import usePatientRouting from '../../hooks/PatientRouting/usePatientRouting'
import { fetchBalance, fetchPatientInvoice } from '../aobPayments/fetchPromises'
import { myInvoiceId } from './constants'
import { BalanceAvailableType } from './tiles/Invoice'
import { usaStates } from '../../components/forms/constants'
import { getProductPricing } from '../../utils/patientInfoApi'
import Order from '../../types/order'
import { sleepMyEquipmentPlaceholder } from '../../resources/images'
import { ResupplyStatusItem } from '../../components/accountTracker/types/AccountTrackerTypes'

export function fetchAccountData() {
  const controller = new AbortController()
  const backupPatientId = useReactiveVar( patientIdFromAppServer )
  const { setCustomer, setAccountTracker, setOrders, setEquipment, setPayers, setRegions, setActiveInvoice, setBillPayInvoice } = useAccountStore()

  // fetch customer data
  useQuery( customerAddressesQuery, {
    onCompleted: ( data ) => {
      setCustomer({
        data: data.customer,
        error: undefined,
        loading: false
      })

      const patient_id = data?.customer?.patient_id ?? backupPatientId
      if ( !patient_id ) {
        // we can't fetch products without patient id
        setEquipment({
          data: undefined,
          error: new ApolloError({
            errorMessage: `Error fetching patient id`
          }),
          loading: false
        })
        reportToSentry( `useAccountData: there is no patient id to get account data`, {
          reason: JSON.stringify({
            data: data
          })
        })
      } else if ( patient_id ) {
        // once patient id is acquired
        fetchResupplyOptions( patient_id, `myaccount`, controller.signal )
          .then( async ({ data }) => {
            if ( !data?.items?.length ) return []

            const skusArray = data?.items.map( ( item: {magento_sku: string}) => { return item.magento_sku })
            const magentoResponse = await apolloClient.query({
              query: getProductsQueryBySkuArray,
              fetchPolicy: `network-only`,
              variables: {
                skus: skusArray
              }
            })

            if ( !magentoResponse?.data || !magentoResponse?.data?.resupplyProducts?.items?.length ) return []

            return data?.items.map( ( item: {magento_sku: string}) => {
              const magentoProduct = magentoResponse.data.resupplyProducts.items.find( ( magentoItem: {sku: string}) => {
                return magentoItem.sku === item.magento_sku
              })

              return {
                ...item,
                image: magentoProduct?.image ?? magentoProduct?.small_image ?? undefined
              }
            })
          })
          .then( customerProducts => {
            if ( !controller.signal.aborted && customerProducts ) {
              setEquipment({
                data: customerProducts,
                error: undefined,
                loading: false
              })
            } else {
              setEquipment({
                data: undefined,
                error: new ApolloError({
                  errorMessage: `No products returned for patient id ${patient_id}`
                }),
                loading: false
              })
            }
          })
          .catch( ( error ) => {
            if ( !controller.signal.aborted ) {
              setEquipment({
                data: undefined,
                error: new ApolloError({
                  errorMessage: getResupplyProductsViaOptionsFetchErrorDisplayMessage( error )
                }),
                loading: false
              })
              reportToSentry( new Error( `useAccountData: Error getting resupply options`, {
                cause: error
              }), {
                patientId: patient_id
              })
            }
          })
      }
    },
    onError: ( error ) => {
      setCustomer({
        data: undefined,
        error,
        loading: false
      })
      setEquipment({
        data: undefined,
        error: new ApolloError({
          errorMessage: `Error fetching patient id`
        }),
        loading: false
      })
      reportToSentry( `useAccountData: Error from customerAddressesQuery`, {
        error: error?.message,
        jsonError: JSON.stringify( error )
      })
    }
  })

  // fetch account tracker data and orders
  useEffect( () => {
    accountTrackerDataFetch()
      .then( async ( response ) => {
        if ( response?.data && response?.meta?.status === `OK` ) {
          // fetch orders
          const skusArray = response?.data?.resupply_status ? response?.data?.resupply_status[`Resupply Status`]?.map( ( item: {dme_id: string}) => { return item.dme_id }) : []
          // need to get magento product for item images
          const magentoResponse = await apolloClient.query({
            query: getProductsQueryBySkuArray,
            fetchPolicy: `network-only`,
            variables: {
              skus: skusArray
            },
            enable: skusArray.length > 0
          })

          // map over each order to assign item images
          const orders = ( response?.data?.resupply?.Resupply || [] )?.map( ( order: Order ) => {
            return {
              order: {
                ...order.order,
                // map over each item in the order
                items: order?.order?.items?.map( ( item ) => {
                  // find the magento product to grab the image from
                  const magentoProduct = magentoResponse.data.resupplyProducts.items.find( ( magentoItem: {
                    sku: string
                  }) => {
                    // find the id by item name
                    const dme_id = response?.data?.resupply_status ? response?.data?.resupply_status[`Resupply Status`]?.find( ( resupplyItem: ResupplyStatusItem ) => {
                      return resupplyItem.itemName === item.item_name
                    })?.dme_id : undefined
                    // use the id to match to magento item
                    return magentoItem.sku === dme_id?.toString()
                  })
                  return {
                    ...item,
                    image: magentoProduct?.image ?? magentoProduct?.small_image ?? {
                      url: sleepMyEquipmentPlaceholder,
                      label: `aeroflow sleep placeholder image`
                    }
                  }
                }) ?? []
              }
            }
          })
          const sortedOrders = orders.sort( ( a: Order, b: Order ) => {
            return b.order.order_date < a.order.order_date ? -1 : 1
          })
          const isPast = ( ( order: Order ) => order.order.items.some( item => item.status === `Delivered` || item.status === `Cancelled` ) )
          const currentOrders = sortedOrders.filter( ( order: Order ) => !isPast( order ) )
          const pastOrders = sortedOrders.filter( ( order: Order ) => isPast( order ) )
          setOrders({
            data: {
              currentOrders: currentOrders || [],
              pastOrders: pastOrders || []
            },
            error: undefined,
            loading: false
          })
          setAccountTracker({
            data: response.data,
            error: undefined,
            loading: false
          })
        } else {
          setAccountTracker({
            data: undefined,
            error: response,
            loading: false
          })
        }
      })
      .catch( ( error ) => {
        setOrders({
          data: undefined,
          error,
          loading: false
        })
        setAccountTracker({
          data: undefined,
          error,
          loading: false
        })
        reportToSentry( new Error( `useAccountData: Error retrieving account tracker data`, {
          cause: error
        }) )
      })
  }, [] )

  // fetch regions
  useQuery( regionsQuery, {
    onCompleted: ( data ) => {
      if ( data?.countries?.length ) {
        // filter and set regions data
        let availableRegions = data.countries.find( ( country: {id: string}) => {
          return country.id === `US`
        })?.available_regions

        const stateCodes = usaStates.map( usaState => { return usaState.value })

        availableRegions = availableRegions.filter( ( region: {code: string}) => {
          return stateCodes.includes( region.code )
        })
        setRegions({
          data: availableRegions,
          error: undefined,
          loading: false
        })
      }
    },
    onError: ( error ) => {
      setRegions({
        data: undefined,
        error: error,
        loading: false
      })
    }
  })

  // fetch payers
  useEffect( () => {
    getProductPricing( true )
      .then( ( data ) => {
        setPayers({
          data: data.data.patient.payers,
          error: undefined,
          loading: false
        })
      })
      .catch( ( error ) => {
        setPayers({
          data: undefined,
          error: error,
          loading: false
        })
        reportToSentry( new Error( `Error getting payers`, {
          cause: JSON.stringify( error ?? `` )
        }) )
      })
  }, [ ] )


  // fetch active invoice
  const { data } = usePatientRouting()
  const hash = data?.patient_hash
  const currentOptions = useReactiveVar( accountNavOptions )
  const checkHasBalance = ({data} : BalanceAvailableType ) => {
    //  check if dominant division is sleep and current balance flag is true
    return data.find( ( item ) => item.division === `Sleep` && item.current_balance_flag )
  }

  useEffect( () => {
    if ( hash ) {
      // check if hash pulls down a valid active patient invoice
      fetchPatientInvoice( hash ).then( data => {
        if ( data?.meta?.status === `OK` && data?.data ) {
          setActiveInvoice({
            data: {
              activeInvoice: true,
              hash
            },
            error: undefined,
            loading: false
          })
          // add invoice option to account nav options if active invoice
          const myInvoiceOption = currentOptions.find( ( option ) => { return option.id === myInvoiceId })
          if ( !myInvoiceOption ) accountNavOptions( [{
            label: `Invoice`,
            id: myInvoiceId
          }, ...currentOptions ] )
        } else {
          setActiveInvoice({
            data: {
              activeInvoice: false,
              hash
            },
            error: undefined,
            loading: false
          })
        }
      })
        .catch( ( error ) => {
          reportToSentry( new Error( `useAccountData: Error getting active invoice data`, {
            cause: error
          }),
          {
            hash
          })
          setActiveInvoice({
            data: undefined,
            error: new ApolloError({
              errorMessage: error?.errorMessage
            }),
            loading: false
          })
        })
    } else {
      setActiveInvoice({
        data: undefined,
        error: new ApolloError({
          errorMessage: `No data available`
        }),
        loading: false
      })
    }
  }, [ data ] )

  // fetch bill pay invoice
  useEffect( () => {
    // check if patient has a balance then build link for the bill pay site
    fetchBalance( ).then( data => {
      if ( data?.meta?.status === `OK` && data?.data?.length ) {
        const balanceData = data?.data[0]
        if ( checkHasBalance( data ) ) {
          // add invoice option to account nav options if active invoice
          const myInvoiceOption = currentOptions.find( ( option ) => { return option.id === myInvoiceId })
          if ( !myInvoiceOption ) accountNavOptions( [{
            label: `Invoice`,
            id: myInvoiceId
          }, ...currentOptions ] )
          const url = balanceData?.patient_hash ? `${process.env.REACT_APP_BILL_PAY_URL}/?sgh=${balanceData.patient_hash}` : ``
          const balance = balanceData?.current_balance ? balanceData.current_balance.toFixed( 2 ) : ``
          setBillPayInvoice({
            data: {
              billPayUrl: url,
              billPayAmount: balance
            },
            error: undefined,
            loading: false
          })
        } else {
          setBillPayInvoice({
            data: undefined,
            error: undefined,
            loading: false
          })
        }
      } else {
        setBillPayInvoice({
          data: undefined,
          error: new ApolloError({
            errorMessage: `No data available`
          }),
          loading: false
        })
      }
    })
      .catch( ( error ) => {
        reportToSentry( new Error( `useAccountData: Error fetching bill pay for patient`, {
          cause: error
        }),
        {
          hash
        })
        setBillPayInvoice({
          data: undefined,
          error: new ApolloError({
            errorMessage: error.message
          }),
          loading: false
        })
      })
  }, [] )
}

export function refetchCustomer() {
  const {setCustomer} = useAccountStore()
  useQuery( customerAddressesQuery, {
    onCompleted: ( data ) => {
      setCustomer({
        data: data.customer,
        error: undefined,
        loading: false
      })
    },
    onError: ( error ) => {
      setCustomer({
        data: undefined,
        error,
        loading: false
      })
      reportToSentry( new Error( `refetchCustomer: Error retrieving customer addresses query`, {
        cause: error
      }) )
    }
  })
}