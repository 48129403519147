import { displayIdentifierTooltips } from 'apollo'
import { useReactiveVar } from '@apollo/client'
import {
  DIGITAL_ABN_PATH,
  EMBED_QUALIFY_FORM_PATH,
  MAINTENANCE_PAGE_PATH,
  MAINTENANCE_PAGE_THANK_YOU_PATH,
  PROMO_PAGE_PATH,
  SNL_ALL_PAGES_PATH
} from 'routes'
import { PAYMENT_PORTAL_PATH } from 'modules/aobPayments/constants'
import {
  Collapsible,
  ContactBlock,
  ContactBlockCol,
  LinksCol,
  PaymentOptionsCol,
  SocialIcons,
  SocialsCol
} from './components'
import { ABOUT_US_LINKS, QUICK_LINKS, SUPPORT_LINKS } from './constants'
import { whichPathsMatch } from 'components/auth/routeUtils'
import { useTokenStore } from 'stores/tokenStore'
import { SleepLinearGradient } from 'components/sleepLinearGradient'
import { nightSkyBkgDsktp, nightSkyBkgMobile } from 'resources/images'
import { useMediaQuery } from 'react-responsive'
import { Container } from '../Container.tsx'
import PaymentOptionsImageGrid from './components/PaymentOptionsImageGrid'
import React from 'react'

export const Footer = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: 976px)`
  })

  const displayTooltips = useReactiveVar( displayIdentifierTooltips )

  const hideFooterPathsMatch = whichPathsMatch( [
    PAYMENT_PORTAL_PATH,
    MAINTENANCE_PAGE_PATH,
    MAINTENANCE_PAGE_THANK_YOU_PATH,
    PROMO_PAGE_PATH,
    DIGITAL_ABN_PATH,
    SNL_ALL_PAGES_PATH,
    EMBED_QUALIFY_FORM_PATH
  ] )

  const { customerToken } = useTokenStore()

  const USERS_QUICK_LINKS = customerToken ? QUICK_LINKS.slice( 1 ) : QUICK_LINKS

  if ( hideFooterPathsMatch.length ) return null
  return (
    <footer className="relative font-light">
      {/* DESKTOP */}
      <div
        className="bg-aeroflowNavy bg-no-repeat bg-center bg-cover lg-wide:pt-5"
        style={{
          backgroundImage: `url(${nightSkyBkgDsktp})`
        }}
      >
        <Container>
          <div className="pb-16 block-container sm:hidden md:hidden lg-wide:block text-white">
            <div className="flex justify-center flex-wrap">
              <div className="px-14 md:px-10 basis-2/6">
                <LinksCol panelHeader="Quick Links" links={USERS_QUICK_LINKS} />
              </div>
              <div className="px-14 md:px-10 basis-2/6">
                <LinksCol panelHeader="About Us" links={ABOUT_US_LINKS} />
              </div>
              <div className="px-14 md:px-10 basis-2/6">
                <LinksCol panelHeader="Support" links={SUPPORT_LINKS} />
              </div>
              <div className="px-14 md:px-10 basis-2/6">
                <SocialsCol />
              </div>
              <div className="px-14 md:px-10 basis-2/6">
                <ContactBlockCol />
              </div>
              <div className="px-14 md:px-10 basis-2/6">
                <PaymentOptionsCol />
              </div>
            </div>
          </div>
        </Container>
      </div>

      {/* MOBILE */}
      <div
        className="md:block lg-wide:hidden pb-8 text-white bg-aeroflowNavy bg-no-repeat bg-center bg-cover"
        style={{
          backgroundImage: `url(${nightSkyBkgMobile})`
        }}
      >
        <div className="flex flex-col justify-start cursor-pointer pt-6">
          <Collapsible panelHeader={`Quick Links`} links={USERS_QUICK_LINKS} />
          <Collapsible panelHeader={`About Us`} links={ABOUT_US_LINKS} />
          <Collapsible panelHeader={`Support`} links={SUPPORT_LINKS} />
          <Collapsible panelHeader="Payment Options" displayBlock={PaymentOptionsImageGrid} />
          <div className="px-0">
            <div className="max-w-48 mx-auto pt-8 pb-8">
              <SocialIcons />
            </div>
            <ContactBlock />
          </div>
        </div>
      </div>
      {
        process.env.REACT_APP_ENVIRONMENT !== `production` &&
        <button
          className="bg-deepSleepBlue p-2 absolute bottom-0 text-sm text-white rounded-tr-lg cursor-pointer hover:opacity-90"
          onClick={() => { displayIdentifierTooltips( !displayTooltips ) }}
        >
          {`${displayTooltips ? `Disable` : `Enable` } CMS Identifier Tooltips`}
        </button>
      }
      <SleepLinearGradient height={isMobile ? 34 : 60} />
    </footer>
  )
}

export default Footer
