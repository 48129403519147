import React, { useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MaskModelSelector } from 'components/SNLSelectors'
import { PageTracker } from '..'
import LoadingSpinner from 'components/LoadingSpinner'
import { EditSelection } from '../EditSelection/EditSelection'
import { useMediaQuery } from 'react-responsive'
import ActionLink from 'components/SNLSelectors/ActionLink'
import { SNL_MASK_BRAND_PATH, SNL_ORDER_SUMMARY_PATH } from '../constants'
import InsuranceAdvantage from '../InsuranceAdvantage/InsuranceAdvantage'
import { INSURANCE_ADVANTAGE_COPY } from '../InsuranceAdvantage/constants'
import { useBaseEquipmentStore } from '../State/baseEquipmentStore'

interface MaskPropTypes {
    cpapMaskDmeID: string;
    cpapMaskModel: string;
    cpapMaskSize: string;
}

const MaskModel = ({ onHandleChange, formValues, ...pageProps }: any ) => {
  const { isLoading, maskData } = useBaseEquipmentStore()
  const navigate = useNavigate()

  const masks = useMemo( () => {
    const category = maskData.find( item => item.sleep_mask_category === formValues?.cpapMaskType )
    const brand = category?.brandsAvailable.find( item => item.brand === formValues?.cpapMaskBrand )
    return brand?.masksAvailable.map( mask => mask.mask ) || []
  }, [ maskData, formValues ] )

  // ensure a mask brand is selected before navigating to the mask-model page to avoid empty results
  useEffect( () => {
    if ( !formValues.cpapMaskBrand ) return navigate( SNL_MASK_BRAND_PATH )
  }, [ ] )

  const handleNext = () => {
    return pageProps.nextPage()
  }

  const isMobile = useMediaQuery({
    query: `(max-width: 976px)`
  })

  const machineComplete = Boolean( formValues?.cpapMachineDmeID && formValues?.cpapMachineModel && formValues?.cpapMachineBrand )

  const handleChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    if ( e.target.name === `modelSelection` ) {
      const { cpapMaskDmeID, cpapMaskModel, cpapMaskSize } = e.target.value as unknown as MaskPropTypes

      onHandleChange({
        cpapMaskDmeID: cpapMaskDmeID || ``,
        cpapMaskModel: cpapMaskModel || ``,
        cpapMaskSize: cpapMaskSize || ``
      })

      const maskComplete = cpapMaskDmeID && cpapMaskModel && cpapMaskSize

      maskComplete && !machineComplete ? handleNext() : navigate( SNL_ORDER_SUMMARY_PATH )

    } else {
      onHandleChange({
        [e.target.name]: e.target.value
      })
    }
  }

  if ( isLoading ) {
    return (
      <div>
        <PageTracker
          title={`Your Mask`}
        />
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div>
      <PageTracker
        title={`Your Mask`}
        description={`To get started, select a mask so that we can provide you with an estimated cost through insurance.`}
      />

      {
        isMobile && <EditSelection activeStep={pageProps.activeStep} />
      }

      <MaskModelSelector
        heading={`Select your current mask model`}
        name="cpapMaskModel"
        maskNames={masks}
        baseEquipment={maskData}
        selectedValue={formValues?.cpapMaskModel}
        selectedBrand={formValues?.cpapMaskBrand}
        selectedMaskType={formValues.cpapMaskType}
        onChange={handleChange}
        showOnMobile
      />

      {
        isMobile &&
            <InsuranceAdvantage
              className="mt-14"
              description={INSURANCE_ADVANTAGE_COPY.maskDescription}
            />
      }

      <ActionLink
        handleClick={pageProps.onHandleDoesNotWantMask}
        text={`Don't want a mask?`}
      />
    </div>
  )
}

export default MaskModel